/**
 * Passenger Store
 * ----------------------------
 *
 * This store manages the passengers, separated from bookable products (activities, trips, accommodation etc.)
 * Each passenger equals a person and can be part of one or more products.
 */
export const usePassengerStore = defineStore('passenger', () => {
    const passengerCount = ref<number>(1);
    const passengers = ref<Passenger[]>([]);

    // If passengers are updated, inform the shop store
    // As maybe products need to be updated/reloaded
    watch(
        passengers,
        () => useShopStore().passengersUpdated(passengers.value),
        { deep: true },
    );

    // The passengers for the current active booking flow
    const currentFlowPassengers = computed(() => passengers.value.filter(p => useShopStore().currentFlowProduct?.passengerRefs.includes(p.ref)));

    /**
     * Adds a new passenger to the store.
     * - Make sure "ref" is recreated, to trigger reactivity (e.g. reload offers from API, remove depending products etc.)
     */
    function addPassenger(passenger: Passenger) {
        passenger.ref = getPassengerRef(passenger);
        passengers.value.push(passenger);
    }

    /**
     * Updates passenger data.
     * - Make sure "ref" is recreated, to trigger reactivity (e.g. reload offers from API, remove depending products etc.)
     */
    function updatePassenger(passenger: Passenger) {
        const i = passengers.value.findIndex(p => p.ref === passenger.ref);
        passenger.ref = getPassengerRef(passenger);
        passengers.value[i] = passenger;
    }

    /**
     * Removes passengers from the store
     */
    function removePassenger(passenger: Passenger) {
        const i = passengers.value.findIndex(p => p.ref === passenger.ref);
        passengers.value.splice(i, 1);
    }

    function reset() {
        passengers.value = [];
    }

    return {
        passengerCount,
        passengers,
        currentFlowPassengers,

        addPassenger,
        updatePassenger,
        removePassenger,
        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
