import { hash, isEqual } from 'ohash';

/**
 * Shop Store
 * ----------------------------
 *
 * The shop store contains all data related to the products, offers and quotes
 * a customer selects to buy during the booking flow.
 */
export const useShopStore = defineStore('shop', () => {
    /**
     * Products
     * ------------------------------------------------------------------------------------------
     */

    const products = ref<Products>({});

    // stores a hash for each product, so we can easily watch it and detect changes in
    // any of the product specifications
    const hashedProducts = computed(() =>
        Object.entries(products.value).reduce<Record<ProductKey, string>>((acc, [key, product]) => {
            acc[key as ProductKey] = hash({ ...product, offer: undefined });
            return acc;
        }, {} as Record<ProductKey, string>),
    );

    // the product for the current active booking flow
    const currentFlowProduct = computed<Product | undefined>(() => {
        const flowStore = useFlowStore();
        return products.value[flowStore.productConfig?.key as ProductKey];
    });

    function addProduct<K extends keyof Products>(key: K, product: Products[K]) {
        products.value[key] = product;
    }

    function removeProduct(key: ProductKey) {
        delete products.value[key];
    }

    // If passengers are updated, make sure to update the passengerRefs
    // (add or remove passengers) for all products, where this is desired
    function passengersUpdated(passengers: Passenger[]) {
        Object.values(products.value)
            .filter(p => !p.config.disablePassengerSync)
            .forEach(product => product.passengerRefs = passengers.map(p => p.ref));
    }

    /**
     * Offers (extracted from products)
     * ------------------------------------------------------------------------------------------
     */

    const offers = computed<Offers>(() => {
        return Object.entries(products.value).reduce<Offers>((acc, [key, product]) => {
            if (product.offer) {
                acc[key as ProductKey] = product.offer as Offer;
            }
            return acc;
        }, {} as Offers);
    });

    const isReactiveOfferLoading = ref(false);

    // the offer for the current active booking flow
    const currentFlowOffer = computed(() => {
        const flowStore = useFlowStore();
        return offers.value[flowStore.productConfig?.key as ProductKey];
    });

    /**
     * load offer for specific product
     */
    const { isLoading } = useLoading();
    async function loadOffer(key: ProductKey) {
        try {
            isLoading.value = true;
            const product = products.value[key];
            if (!product) {
                return false;
            }
            const passengers = usePassengerStore().passengers.filter(passenger => product.passengerRefs.includes(passenger.ref));
            // If there are no passengers, remove the offer, as offers without passengers have no meaning
            if (!passengers.length) {
                delete product.offer;
                return false;
            }

            if (product.config.type === 'activity') {
                ({ data: product.offer } = await apiLoadActivityOffer(product as ProductActivity, passengers));
            }
            if (product.config.type === 'p2p') {
                ({ data: product.offer } = await apiLoadFinalTripOffer(product as ProductP2P, passengers));
            }
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Watch Product changes
     * ------------------------------------------------------------------------------------------
     */

    // If any of the product specification changes, reload the offer
    // - only for products where something changed (hash)
    // - only for products that are set to auto load the offer
    // - watch is paused so initial store hydration (from localStorage) does not trigger the watch
    useWatchOnReady(hashedProducts, (newValue, oldValue) => {
        Object.entries(newValue).forEach(([k, hash]) => {
            const key = k as ProductKey;
            const product = products.value[key];

            const somethingChanged = oldValue[key] !== hash;

            if (somethingChanged) {
                // Load new offer for product
                if (isReactiveOfferLoading.value
                    && !product?.config.disableAutoLoadOffer) {
                    loadOffer(key);
                }

                // Whenever a product is changed that is not an upsell, reset all products
                // that are not the current product
                // - this is to make sure that only one MAIN product is active at a time
                // - upsells can be added to the main product, but not the other way around
                if (!product?.config.isUpsell) {
                    const productsToReset = Object.values(products.value).filter(p => p !== product);

                    productsToReset.forEach((p) => {
                        removeProduct(p.config.key);

                        // Reset hotel store if hotel product is removed
                        if (p.config.type === 'hotel') {
                            useHotelStore().reset();
                        }

                        // Reset trip store if trip product is removed
                        if (p.config.type === 'p2p') {
                            useTripStore().reset();
                        }
                    });
                }
            }
        });
    });

    /**
     * Quote
     * ------------------------------------------------------------------------------------------
     */
    const quote = ref<Quote>();

    // If any of the offers change, reload the quote, so we always have up-to-date prices
    useWatchOnReady(offers, (newVal, oldVal) => {
        if (isEqual(newVal, oldVal)) {
            return;
        }

        loadQuote();
    }, { deep: true });

    /**
     * load quote for all offers
     */
    async function loadQuote() {
        ({ data: quote.value } = await apiLoadQuote(Object.values(offers.value), usePassengerStore().currentFlowPassengers));
        return true;
    }

    function resetQuote() {
        quote.value = undefined;
    }

    /**
     * E-commerce tracking: T2, T3
     * ------------------------------------------------------------------------------------------
     */
    const { analyticsTrackOfferChanges } = useOfferFlowAnalytics();

    // Watch any changes to offers and track analytics
    useWatchOnReady(offers, (newVal: Offers, oldVal: Offers) => {
        analyticsTrackOfferChanges(newVal, oldVal);
    }, { deep: true });

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        products.value = {};
        isReactiveOfferLoading.value = false;
        quote.value = undefined;
    }

    return {
        // Products
        products,
        hashedProducts,
        currentFlowProduct,
        addProduct,
        removeProduct,
        passengersUpdated,

        // Offers
        offers,
        isReactiveOfferLoading,
        currentFlowOffer,
        loadOffer,

        // Quote
        quote,
        loadQuote,
        resetQuote,

        reset,
    };
}, {
    persist: {
        omit: [
            'isReactiveOfferLoading',
        ],
        ...storePersist.storePersistOptions,
    },
});
