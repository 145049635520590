/**
 * Trip Store
 * ----------------------------
 *
 * The trip store contains all data related to P2P trips
 */
export const useTripStore = defineStore('trip', () => {
    const shopStore = useShopStore();
    const passengerStore = usePassengerStore();

    const placesPopular = ref<Place[]>();
    const places = ref<Place[]>();

    const departureTimeMode = ref<'departure' | 'arrival'>('departure');
    const departureTime = ref<number>();
    const departureDate = computed(() => zurichDate()); // TODO

    const stayDuration = ref<number>(0);

    const routeVariantUp = ref<RouteVariant | undefined>(undefined);
    const routeVariantDown = ref<RouteVariant | undefined>(undefined);

    const tripsUp = ref<Trip[]>([]);
    const tripsDown = ref<Trip[]>([]);

    useWatchOnReady(routeVariantUp, loadTripsUp);
    useWatchOnReady(routeVariantDown, loadTripsDown);

    /**
     * Load trips for:
     * - Trip up
     * - Trip down if its a roundtrip
     *
     * Load trips in parallel to speed up the process
     */
    const { isLoading } = useLoading();
    async function loadTrips() {
        try {
            isLoading.value = true;
            const product = toRef(shopStore.currentFlowProduct as ProductP2P);
            const isRoundtrip = product.value.isRoundtrip; // Only fetch down trips if its a roundtrip
            const [tripsUpResponse, tripsDownResponse] = await Promise.all([
                fetchTripsUp(),
                isRoundtrip ? fetchTripsDown() : Promise.resolve({ data: [] }),
            ]);

            tripsUp.value = tripsUpResponse.data;
            tripsDown.value = tripsDownResponse.data;
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Load trips for the up direction (ORIGIN -> DESTINATION)
     * Function is for standalone loading, including controlling loading state and error handling
     */
    async function loadTripsUp() {
        try {
            isLoading.value = true;
            tripsUp.value = [];
            ({ data: tripsUp.value } = await fetchTripsUp());
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Load trips for the down direction (DESTINATION -> ORIGIN)
     * Function is for standalone loading, including controlling loading state and error handling
     */
    async function loadTripsDown() {
        try {
            isLoading.value = true;
            tripsDown.value = [];
            ({ data: tripsDown.value } = await fetchTripsDown());
            return true;
        }
        catch (error) {
            handleError(error);
            return false;
        }
        finally {
            isLoading.value = false;
        }
    }

    /**
     * Load trips (and offers) for the up direction (ORIGIN -> DESTINATION)
     */
    async function fetchTripsUp() {
        const product = shopStore.currentFlowProduct as ProductP2P;
        const passengers = passengerStore.passengers.length ? passengerStore.passengers : passengerStore.passengerCount;
        const direction = 'up';
        const routeVariant = routeVariantUp.value;

        return apiLoadSingleTripOffers(
            product,
            passengers,
            direction,
            routeVariant,
        );
    }

    /**
     * Load trips (and offers) for the down direction (DESTINATION -> ORIGIN)
     */
    async function fetchTripsDown() {
        const product = shopStore.currentFlowProduct as ProductP2P;
        const passengers = passengerStore.passengers.length ? passengerStore.passengers : passengerStore.passengerCount;
        const direction = 'down';
        const routeVariant = routeVariantDown.value;

        return apiLoadSingleTripOffers(
            product,
            passengers,
            direction,
            routeVariant,
        );
    }

    /**
     *
     */
    function reset() {
        placesPopular.value = [];
        places.value = [];
        departureTimeMode.value = 'departure';
        departureTime.value = undefined;
        routeVariantUp.value = undefined;
        routeVariantDown.value = undefined;
        tripsUp.value = [];
        tripsDown.value = [];
    }

    return {
        placesPopular,
        places,
        departureTimeMode,
        departureTime,
        departureDate,
        stayDuration,

        routeVariantUp,
        routeVariantDown,

        tripsUp,
        tripsDown,

        loadTrips,
        loadTripsUp,
        loadTripsDown,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
