import { differenceInYears, isAfter, startOfDay, subYears } from 'date-fns';
import { hash } from 'ohash';
import { uid } from 'radash';

/**
 *
 * Creates a unique reference for passenger.
 *
 * The passenger "ref" is used everywhere in the app and backend API to reference to a passenger.
 * Whenever important data of the passenger changes, the "ref" should be updated.
 * Because a passenger with a different "dateOfBirth" for example is technically not really the same passenger.
 *
 * Ony relevant fields update the ref. So if you change the passenger name, the ref stays the same.
 * This would then mean, that offers do not need to be re-fetched.
 *
 */
export function getPassengerRef(passenger: Passenger) {
    return `passenger-${hash([
        passenger.uuid,
        passenger.dateOfBirth,
        passenger.keycard,
        passenger.reductionCards,
    ])}`;
}

/**
 *
 * Creates a unique UUID for passenger. This UUD never changes, even if the passenger data changes.
 * This is mainly used inside the frontend app, for example as a :key in a v-for loop.
 */
export function getPassengerUUID() {
    return uid(20);
}

/**
 * Returns passenger age based at the date of the trip based on the date of birth
 */
export function getPassengerAge(dateOfBirth?: Date, tripDate?: Date): number | undefined {
    if (!dateOfBirth || !tripDate) {
        return undefined;
    }
    return differenceInYears(tripDate, dateOfBirth);
};

/**
 * Returns, if the passenger is a toddler based on the date of birth and a trip date for comparison.
 */
export function isToddler(dateOfBirth?: Date, tripDate?: Date): boolean {
    return (getPassengerAge(dateOfBirth, tripDate) ?? 0) < configPassengerAges.AGE_CHILD;
};

/**
 * Returns, if the passenger is an adult (for hotels) based on the date of birth and a trip date for comparison.
 */
export function isHotelAdult(dateOfBirth?: Date, tripDate?: Date): boolean {
    return (getPassengerAge(dateOfBirth, tripDate) ?? 0) >= configPassengerAges.AGE_HOTEL_ADULT;
};

/**
 * Returns a passenger age description based on the age.
 * @param age
 * @returns text description of the age
 */
export function getPassengerAgeDescription(age: number | undefined, includeList = [
    'toddler',
    'child',
    'teen',
    'adult',
    'senior',
]): string {
    const { t: $t } = useNuxtApp().$i18n;

    if (age !== undefined && age >= configPassengerAges.AGE_SENIOR && includeList.includes('senior')) {
        return $t(configPassengerAgeDescriptions.AGE_SENIOR);
    }
    else if (age !== undefined && age >= configPassengerAges.AGE_ADULT && includeList.includes('adult')) {
        return $t(configPassengerAgeDescriptions.AGE_ADULT);
    }
    else if (age !== undefined && age >= configPassengerAges.AGE_TEEN && includeList.includes('teen')) {
        return $t(configPassengerAgeDescriptions.AGE_TEEN);
    }
    else if (age !== undefined && age >= configPassengerAges.AGE_CHILD && includeList.includes('child')) {
        return $t(configPassengerAgeDescriptions.AGE_CHILD);
    }
    else if (age !== undefined && age < configPassengerAges.AGE_CHILD && includeList.includes('toddler')) {
        return $t(configPassengerAgeDescriptions.AGE_TODDLER);
    }
    return $t(configPassengerAgeDescriptions.AGE_ADULT);
}
